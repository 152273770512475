import { Box, Grid, Typography, Button, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { emptyStore } from "../../redux-features/user/userSlice";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { useMutation } from "react-query";
import { userLog } from "../_api";
import { Link, useNavigate } from "react-router-dom";
import RewardsModal from "../lessons/details/RewardsModal";
import TranslateModal from "../lessons/details/TranslateModal";
import Notifications from "../notifications";
import LogoutIcon from "@mui/icons-material/Logout";
import WorldleGame from "./IconsSvg/WorldleGame";
import { useTheme } from "@mui/material/styles";
import LogoImage from "./LogoImage";
import { isStatic } from "./isStatic";

export default function SkillSectionsTopBar() {
  const { mutate: log } = useMutation(userLog);
  const [open, setOpen] = useState(false);
  const [openTranslate, setOpenTranslate] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: "5px",
          mb: { md: "5px", xs: "45px" },
          ml: { lg: "140px", sm: "120px" }
        }}
      >
        {
          !isMobile &&
          <LogoImage />
        }
        {
          !isStatic &&
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: { sm: "10px", xs: "4px" },
            }}
          >
            <Button
              sx={{
                fontSize: { sm: "14px", xs: "12px" }
              }}
              variant="outlined"
              // color="success"
              onClick={() => {
                setOpenTranslate(true);
                log({
                  pageName: "Alt Space-vr",
                  buttonName: "Translate It",
                });
              }}
            >
              Translate It
            </Button>

            <Link
              to="/wordscape"
              onClick={() =>
                log({
                  pageName: "Skill Details Assess",
                  buttonName: "Word Game",
                })
              }
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 0.3,
                  padding: { sm: "0.5rem 1rem", xs: "5px" },
                  borderRadius: "0.8rem",
                  alignItems: "center",
                  "&:hover": {
                    backgroundColor: theme.palette.primary.light,
                  },
                }}
              >
                <WorldleGame color={theme.palette.primary.main} />
                <Typography
                  sx={{ display: { sm: "block", xs: "none" } }}
                  variant="body1"
                  fontWeight={700}
                  color="primary"
                >
                  Wordle
                </Typography>
              </Box>
            </Link>

            <div
              className="logoutIcon"
              onClick={() => {
                if (sessionStorage.getItem("access") === "ok") {
                  Swal.fire({
                    title: "",
                    text: "It's a teacher view, so you can't logout!",
                    icon: "info",
                    confirmButtonText: "OK",
                  });
                  return;
                }
                dispatch(emptyStore());
                localStorage.removeItem("token");
                navigate("/get-started");
                log({
                  pageName: "Module",
                  buttonName: "Logout Button",
                });
              }}
            >
              <div className="logged" style={{ background: "white" }}>
                <LogoutIcon
                  sx={{
                    width: { xs: "25px", md: "40px" },
                    height: { xs: "25px", md: "40px" },
                    color: theme.palette.primary.main,
                  }}
                />
              </div>
            </div>
            <Notifications />
            <RewardsModal open={open} setOpen={setOpen} />
            <TranslateModal
              openTranslate={openTranslate}
              setOpenTranslate={setOpenTranslate}
            />
          </Box>
        }
      </Box>
    </>
  );
}
